import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #1fa574;
    border: transparent;
  }

  html{
    @media (max-width: 1700px){
      font-size: 75%;
    }
  }

  body{
    background: #1b1b1b;
    font-family: 'Inter', sans-serif;
  }
  button{
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    background: transparent;
    border: 3px solid #23d997;
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 1rem 2rem;
    &:hover{
      background-color: #23d977;
      color: #ffffff;
    }
  }
  h2{
    font-weight: lighter;
    font-size: 4rem;
  }
  h3{
    color: #ffffff;
  }
  h4{
    font-size: 2rem;
    font-weight: bold;
  }
  a{
    font-size: 1.1rem;
  }
  span{
    font-weight: bold;
    color: #23d997;
  }
  p{
    padding: 3rem 0;
    color: #cccccc;
    font-size: 1.4rem;
    line-height: 150%;
  }
`;

export default GlobalStyle;