import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaAlignLeft } from 'react-icons/fa';

const Nav = ( { navStatus, setNavStatus } ) => {
  const { pathname } = useLocation();
  const navToggle = () => {
    setNavStatus( !navStatus )
  }
  return (
    <StyledNav>
      <h1><Link id="logo" to="/">Ronisha Arts</Link></h1>
      <div
        className="hamburger-menu"
        onClick={ navToggle }>
        <FaAlignLeft />
      </div>
      <ul className={ navStatus ? "links-active" : "" }>
        <li>
          <Link to="/" onClick={ navToggle }>About</Link>
          <Line
            transition={ { duration: 0.75 } }
            initial={ { width: "0%" } }
            animate={ { width: pathname === '/' ? "50%" : "0%" } }
          />
        </li>
        <li>
          <Link to="/work" onClick={ navToggle }>Works</Link>
          <Line
            transition={ { duration: 0.75 } }
            initial={ { width: "0%" } }
            animate={ { width: pathname === '/work' ? "50%" : "0%" } }
          />
        </li>
        <li>
          <Link to="/contact" onClick={ navToggle }>Contact</Link>
          <Line
            transition={ { duration: 0.75 } }
            initial={ { width: "0%" } }
            animate={ { width: pathname === '/contact' ? "50%" : "0%" } }
          />
        </li>
      </ul>
    </StyledNav>
  )
}
const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background: #282828;
  clear: both;
  a{
    color: #fff;
    text-decoration: none;
  }
  ul{
    display: flex;
    list-style: none;
  }
  .hamburger-menu{
    display: none;
  }
  #logo{
    font-size: 1.6rem;
    font-weight: lighter;
    font-family: 'Lobster', cursive;
  }
  li{
    padding-left: 5rem;
    position: relative;
  }
  @media screen and (max-width: 1300px) {
    min-height: 7vh;
    display: block;
    padding: 1rem 2rem 4rem;
    ul{
      position: absolute;
      left: 0%;
      top: 60px;
      padding: 2rem 0;
      justify-content: space-around;
      width: 100%;
      transition: all 0.5s ease;
      background-color: #444141;
      transform: translateX(-200%);
    }
    .links-active{
      top: 60px;
      transform: translateX(0%);
      border-bottom: 3px solid #23d997;
    }
    li{
      padding: 0;
    }
    h1{
      float: left;
    }
    #logo{
      font-size: 2.5rem;
      float: left;
    }
    .hamburger-menu{
      display: inline-block;
      cursor: pointer;
      color: #fff;
      font-size: 2rem;
      margin-top: 0.5rem;
      float: right;
    }
  }
`;

const Line = styled( motion.div )`
  height: 0.3rem;
  background: #23d997;
  width: 0%;
  position: absolute;
  bottom: -80%;
  @media screen and (max-width: 1300px) {
    left: 0%;
  }
`;
export default Nav;
