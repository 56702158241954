import React from 'react';
import styled from 'styled-components';
import { About } from '../styles';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';

const FaqsSection = () => {
  return (
    <Faqs>
      <h2>Any Questions? <span>FAQs</span></h2>
      <AnimateSharedLayout>
        <Toggle title="About Me? 🤔 ">
          <div className="answer">
            <p>I am <span>Ronisha Shrestha</span> from Bhaktapur, Nepal.</p>
            <p>Pursuing my Bachelor's career in Fine Arts from <strong><span>Lalit Kala Campus</span></strong></p>
          </div>
        </Toggle>
        <Toggle title="Interest and Hobbies? 🎨 ">
          <div className="answer">
            <p>Everybody has their own interest and hobbies, mine are:</p>
            <p>Artistic Activities which includes sketching, drawing, painting.</p>
            <p>Cooking and Baking.</p>
            <p>Listening to music and humming.</p>
            <p>Exercising and healthcare.</p>
            <p>Indoor Sports.</p>
          </div>
        </Toggle>
        <Toggle title="Why crazy in art? 🖌️ ">
          <div className="answer">
            <p>Well there are many reasons:</p>
            <p>One of them is, it connects mind and heart to print something very beautiful that no one ever imagined of.</p>
          </div>
        </Toggle>
        <Toggle title="Potrate your picture? 🖼️ ">
          <div className="answer">
            <p>Well it depends, contact me for more info.</p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faqs>
  )
}

const Faqs = styled( About )`
  min-height: 50vh;
  display: block;
  span{
    display: block;
  }
  h2{
    padding-bottom:2rem;
    font-weight: lighter;
  }
  .faq-line{
    background: #ccc;
    height: 0.2rem;
    margin: 2rem 0;
    width:100%
  }
  .question{
    cursor: pointer;
  }
  h4{
    font-size: 1.3rem;
  }
  .answer{
    padding: 2rem 0rem;
    /* text-transform: capitalize; */
    p{
      font-size: 1.2rem;
      padding: 0;
    }
    @media screen and (max-width: 1300px) {
      p{
        font-size: 1.3rem;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    h2{
      padding-top: 3rem;
      font-size: 3rem;
    }
    .faq-line{
      width: 80%;
      margin: 2rem auto;
    }
    p{
      padding: 1rem 2rem 0 !important;
    }
  }
`;

export default FaqsSection;