import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <StyledFooter>
      <p>
        copyright &copy; <span>ronisha arts</span> { new Date().getFullYear() }. All Rights Reserved.
      </p>
      <div className="soc">
        <Link to={ { pathname: "https://www.facebook.com/ronishaarts/" } } target="_blank">
          <FaFacebook className="icons" size="2rem" />
        </Link>
        <Link to={ { pathname: "https://www.instagram.com/ronisha_arts/" } } target="_blank">
          <FaInstagram className="icons" size="2rem" />
        </Link>
        <Link to={ { pathname: "mailto:ronishashrestha152@gmail.com?subject=hello from website" } } target="_blank">
          <FaEnvelope className="icons" size="2rem" />
        </Link>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  background: #282828;
  p{
    padding: 1.5rem 2rem 1rem;
    font-size: 1.2rem !important;
    text-align: center;
    text-transform: capitalize;
  }
  .soc{
    text-align: center;
    padding-bottom: 1.5rem;
    a{
      padding: 0 0.5rem;
      color: #23d997;
    }
  }
  .icons{

  }
`;

export default Footer;
