import React from "react";
import clock from "../img/clock.svg";
import effort from "../img/effort.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/home2.jpg";
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { reveal } from "../animation";
import { useScroll } from "./useScroll";

const ServicesSection = () => {
	const [element, controls] = useScroll();
	return (
		<Services
			transition={{ duration: 0.75 }}
			ref={element}
			variants={reveal}
			animate={controls}
			initial="hidden"
			style={{ background: "#292b2b" }}
		>
			<Image className="image-holder">
				<img src={home2} alt="Ronisha Shrestha arts" />
			</Image>
			<ServiceDescription>
				<h2>
					Four <span>ESSENTIAL</span> qualities.
				</h2>
				<Cards>
					<Card>
						<div className="icon">
							<img src={clock} alt="clock icon svg" />
							<h3>Time</h3>
						</div>
						<div className="text">
							<p>Lost time is never found again.</p>
						</div>
					</Card>
					<Card>
						<div className="icon">
							<img src={effort} alt="effort icon svg" />
							<h3>Effort</h3>
						</div>
						<div className="text">
							<p>Knowing is not enough, we must apply with good effort.</p>
						</div>
					</Card>
					<Card>
						<div className="icon">
							<img src={money} alt="money icon svg" />
							<h3>Money</h3>
						</div>
						<div className="text">
							<p>No work, No money, work work work... 😉</p>
						</div>
					</Card>
					<Card>
						<div className="icon">
							<img src={teamwork} alt="teamwork icon svg" />
							<h3>Teamwork</h3>
						</div>
						<div className="text">
							<p>Teamwork makes the dream work.</p>
						</div>
					</Card>
				</Cards>
			</ServiceDescription>
		</Services>
	);
};

const Services = styled(About)`
	text-align: center;
	h2 {
		padding: 0 1rem 5rem;
		text-shadow: -3px 3px 3px #000;
	}
	.text {
		display: flex;
		justify-content: center;
		text-shadow: -1px 2px 2px #000;
	}
	p {
		padding: 1rem 2rem 3rem;
	}
	@media screen and (max-width: 1300px) {
		min-height: 50vh;
		background: url("${home2}") !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		background-attachment: fixed !important;
		background-size: cover !important;
		.image-holder {
			display: none;
		}
		p {
			padding: 3rem 2rem 1rem;
		}
	}
`;
const ServiceDescription = styled(Description)`
	padding: 0;
	@media screen and (max-width: 1300px) {
		padding: 2rem 0 3rem;
	}
`;

const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: 1300px) {
		justify-content: center;
	}
`;

const Card = styled.div`
	flex-basis: 50%;
	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		h3 {
			margin-left: 1rem;
			background: #ffffff;
			color: #000000;
			padding: 1rem;
		}
	}
`;
export default ServicesSection;
