import React from 'react';
import styled from 'styled-components';
import logoSvg from '../logo.svg';
import ScrollTop from '../components/ScrollTop';
import { Link } from 'react-router-dom';
import { anim404 } from '../animation';
import { motion } from 'framer-motion';

const Error = () => {
  return (
    <StyledError
      variants={ anim404 }
      exit="exit"
      initial="hidden"
      animate="show"
    >
      <ScrollTop />
      <div className="txt-holder">
        <p className="error">Error</p>
        <h1 className="p404"><span>404</span></h1>
        <p className="pnf">Page Not Found !</p>
        <Link to="/" className="button">&laquo; Go Back Home</Link>
      </div>
    </StyledError>
  )
}
const StyledError = styled( motion.div )`
  *{
    margin: 0;
    padding: 0;
  }
  background:url(${ logoSvg });
  background-repeat: no-repeat !important;
  background-size: 70vh !important;
  background-position: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  .txt-holder{
    background: rgba(255, 36, 10, 0.4);
    width: 50%;
    padding: 2rem 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    outline: 2px solid #23d997;
    outline-offset: 10px;
  }
  .error, .p404, .pnf{
    color: #fff;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: -3px 3px 10px #000;
  }
  .p404{
    font-size: 5rem;
  }
  .button{
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    border: 3px solid #23d997;
    transition: all 0.5s ease;
    text-decoration: none;
    cursor: pointer;
    padding: 1rem 2rem;
    background: #23d997;
    outline: 3px solid #23d997;
    outline-offset: 3px;
    text-shadow: -3px 3px 8px #000;
  }
  @media screen and (max-width: 1300px) {
    background-size: 50vh !important;
    .error, .p404, .pnf{
    font-size: 1.4rem;
    }
    .p404{
      font-size: 3rem;
    }
    .button{
      padding: 1rem;
    }
  }
`;
export default Error;
