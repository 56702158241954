import styled from 'styled-components';
import { motion } from 'framer-motion';

export const About = styled( motion.div )`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 10rem;
  color: #ffffff;
  @media screen and (max-width: 1300px) {
    display: block;
    padding: 0;
    text-align: center;
  }
`;

export const Description = styled( motion.div )`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;
  h2{
    font-weight: lighter;
  }
  @media screen and (max-width: 1300px) {
    padding: 2rem 0 0;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      padding: 2rem 3rem;
      line-height: 30px;
    }
  }
`;

export const Image = styled( motion.div )`
  flex: 1;
  overflow: hidden;
  z-index: 2;
  img{
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  @media screen and (max-width: 1300px) {
    padding: 3rem 0 0;
  }
`;

export const Hide = styled( motion.div )`
  overflow: hidden;
`;