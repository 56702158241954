import React from 'react';
import AboutSection from '../components/AboutSection';
import ServicesSection from '../components/ServicesSection';
import FaqsSection from '../components/FaqsSection';
// Animation
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import ScrollTop from '../components/ScrollTop';


const AboutUs = () => {
  return (
    <motion.div
      variants={ pageAnimation }
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <ScrollTop />
      <AboutSection />
      <ServicesSection />
      <FaqsSection />
    </motion.div>
  )
}

export default AboutUs;
