import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Toggle = ( { title, children } ) => {
  const [ toggle, setToggle ] = useState( false );
  return (
    <motion.div layout className="question" onClick={ () => setToggle( !toggle ) }>
      <motion.h4 layout>{ title }</motion.h4>
      <motion.span layout style={ { textAlign: 'right', margin: '-20px 25px 0 0' } }>
        {
          toggle ? <FaMinus size='15px' /> : <FaPlus size='15px' />
        }
      </motion.span>
      { toggle ? children : '' }
      <div className="faq-line"></div>
    </motion.div>
  )
}
export default Toggle;