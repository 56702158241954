import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// Animation
import { motion } from 'framer-motion';
import { pageAnimation, titleAnimation, lineAnimation, photoAnimation } from '../animation';
import ScrollTop from '../components/ScrollTop';

const WorkDetail = ( { works } ) => {
  const history = useHistory();
  const url = history.location.pathname;
  const [ art, setArt ] = useState( null );

  useEffect( () => {
    const selectedWork = works.find( work => `/work/${ work.fields.url }` === url );
    setArt( selectedWork );
  }, [ works, url ] );

  return (
    <>
      <ScrollTop />
      {art && (
        <Details
          variants={ pageAnimation }
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <Headline>
            <motion.h2 variants={ titleAnimation }>{ art.fields.title }</motion.h2>
            <Line variants={ lineAnimation } className="line"></Line>
            <Hide>
              <motion.img variants={ photoAnimation } src={ art.fields.mainImg.fields.file.url } alt="art" />
            </Hide>
          </Headline>
          <Description>
            <p>{ art.fields.description }</p>
          </Description>
          <Awards>
            {
              art.fields.artDetails.aboutPainting.map( ( award, index ) => (
                <Award
                  key={ index }
                  title={ award.title }
                  description={ award.description }
                />
              ) )
            }
          </Awards>
        </Details>
      ) }
    </>
  )
}

const Award = ( { title, description } ) => {
  return (
    <AwardStyle>
      <h3>{ title }</h3>
      <div className="line"></div>
      <p>{ description }</p>
    </AwardStyle>
  )
}

const Details = styled( motion.div )`
  color: #fff;
`;

const Headline = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
  h2{
    padding: 1rem 0;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
  }
  img{
    width: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    h2{
      padding: 1.5rem 2rem;
      font-size: 2rem;
    }
  }
`;

const Line = styled( motion.div )`
  height: 0.3rem;
  background: #23d997;
  margin-bottom: 2rem;
`;

const Hide = styled.div`
  overflow: hidden;
`;

const Description = styled.div`
  background: #282828;
  padding: 1rem 10rem;
  p{
    color: #fff;
  }
  @media screen and (max-width: 1300px) {
    padding: 1rem 3rem;
    
  }
`;

const Awards = styled.div`
  display: flex;
  margin: 5rem 10rem;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1300px) {
    display: flex;
    margin: 4rem 0 2rem;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      padding: 0 0 3rem !important;
    }
  }
`;

const AwardStyle = styled.div`
  padding: 5rem 1rem;
  h3{
    font-size: 1.5rem;
    text-transform: capitalize;
    text-align: center;
  }
  .line{
    width: 50%;
    background: #23d997;
    height: 0.2rem;
    margin: 1rem auto;
  }
  p{
    padding: 2rem 0;
    text-transform: capitalize;
    text-align: center;
  }
  @media screen and (max-width: 1300px) {
    padding: 1rem;
    .line{
      margin: 1rem auto;
    }
  }
`;

export default WorkDetail;