export const pageAnimation = {
	hidden: {
		opacity: 0,
		// y: 300
	},
	show: {
		opacity: 1,
		// y: 0,
		transition: {
			duration: 0.5,
			when: "beforeChildren",
			staggerChildren: 0.25,
		},
	},
	exit: {
		opacity: 0,
		transation: {
			duration: 0.5,
		},
	},
};

export const titleAnimation = {
	hidden: {
		y: 200,
	},
	show: {
		y: 0,
		transition: {
			duration: 0.75,
			ease: "easeOut",
		},
	},
};

export const fade = {
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 1,
		transition: {
			ease: "easeOut",
			duration: 0.5,
		},
	},
};

export const photoAnimation = {
	hidden: {
		scale: 1.5,
		opacity: 0,
	},
	show: {
		scale: 1,
		opacity: 1,
		transition: {
			ease: "easeOut",
			duration: 0.75,
			delay: 1.1,
		},
	},
};

export const lineAnimation = {
	hidden: {
		width: "0%",
	},
	show: {
		width: "100%",
		transition: {
			duration: 1,
		},
	},
};

export const lineAnimation2 = {
	hidden: {
		width: "0%",
	},
	show: {
		width: "100%",
		transition: {
			duration: 0.5,
		},
	},
};

export const pageAnimSlider = {
	hidden: {
		x: "-160%",
		skew: "45deg",
	},
	show: {
		x: "100%",
		skew: "0deg",
		transition: {
			ease: "easeOut",
			duration: 0.5,
		},
	},
};

export const pageAnimSliderContainer = {
	hidden: {
		opacity: 1,
	},
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.11,
			ease: "easeOut",
			duration: 1,
		},
	},
};

export const artContainer = {
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.15,
			ease: "easeOut",
			duration: 1,
			when: "beforeChildren",
		},
	},
};

export const reveal = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			ease: "easeOut",
			duration: 2,
		},
	},
};

export const anim404 = {
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 1,
		transition: {
			duration: 2,
		},
	},
};
