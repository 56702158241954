import React from "react";
import home1 from "../img/home1.jpg";
import { About, Description, Hide, Image } from "../styles";
import { motion } from "framer-motion";
import { titleAnimation, fade, photoAnimation } from "../animation";
import Wave from "./Wave";
import { Link } from "react-router-dom";

const AboutSection = () => {
	return (
		<About>
			<Description>
				<motion.div>
					<Hide>
						<motion.h2 variants={titleAnimation}>Hello there,</motion.h2>
					</Hide>
					<Hide>
						<motion.h2 variants={titleAnimation}>
							<span className="dreams">HEARTILY</span> welcome
						</motion.h2>
					</Hide>
					<Hide>
						<motion.h2 variants={titleAnimation}>to my website.</motion.h2>
					</Hide>
				</motion.div>
				<motion.p variants={fade}>
					For any type of paintings or ideas please free feel to contact me.{" "}
				</motion.p>
				<motion.button variants={fade}>
					<Link to="/contact" style={{ textDecoration: "none", color: "#fff" }}>
						Contact Me
					</Link>
				</motion.button>
			</Description>
			<Image>
				<motion.img
					variants={photoAnimation}
					src={home1}
					alt="Ronisha Shrestha"
				/>
			</Image>
			<Wave />
		</About>
	);
};

export default AboutSection;
