import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// Animation
import { motion } from "framer-motion";
import LazyLoad from "react-lazyload";
import {
	pageAnimation,
	photoAnimation,
	lineAnimation,
	pageAnimSlider,
	pageAnimSliderContainer,
	titleAnimation,
	artContainer,
} from "../animation";
import ScrollTop from "../components/ScrollTop";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Works = ({ works }) => {
	if (!works) {
		return <h1>Loading...</h1>;
	}
	return (
		<Work
			style={{ color: "#282828", background: "#f2f2f2" }}
			variants={pageAnimation}
			exit="exit"
			initial="hidden"
			animate="show"
		>
			<ScrollTop />
			<motion.div variants={pageAnimSliderContainer}>
				<Frame1 variants={pageAnimSlider}></Frame1>
				<Frame2 variants={pageAnimSlider}></Frame2>
				<Frame3 variants={pageAnimSlider}></Frame3>
				<Frame4 variants={pageAnimSlider}></Frame4>
			</motion.div>
			{works.map((item, key) => (
				<Art key={key}>
					<LazyLoad
						height={200}
						offset={-130}
						once={false}
						placeholder={
							<SkeletonTheme color="#eceaea" highlightColor="#e0e0e0">
								<Skeleton wrapper={skeletonh1} count={1} />
								<Skeleton height={300} />
								<Skeleton wrapper={skeletonbutton} count={1} />
							</SkeletonTheme>
						}
					>
						<motion.h2 variants={titleAnimation}>{item.fields.title}</motion.h2>
						<motion.div variants={lineAnimation} className="line"></motion.div>
						<Link to={`/work/${item.fields.url}`}>
							<Hide>
								<motion.img
									className="dyn-img"
									variants={photoAnimation}
									src={item.fields.mainImg.fields.file.url}
									alt={`Ronisha Arts (${item.fields.title})`}
								/>
							</Hide>
						</Link>
						<div className="button">
							<motion.button variants={artContainer}>
								<Link
									to={`/work/${item.fields.url}`}
									style={{ textDecoration: "none", color: "#fff" }}
								>
									Details &raquo;
								</Link>
							</motion.button>
						</div>
					</LazyLoad>
				</Art>
			))}
		</Work>
	);
};
const skeletonh1 = styled.h1`
	font-size: 3rem;
	width: 50%;
	margin: 1rem 0;
`;

const skeletonbutton = styled.div`
	font-size: 3rem;
	width: 15%;
	margin: 1rem auto;
`;

const Work = styled(motion.div)`
	min-height: 100vh;
	overflow: hidden;
	padding: 0 10rem;
	h2 {
		padding: 1rem 0;
		text-transform: capitalize;
		font-size: 3rem;
	}
	a {
		color: #000 !important;
	}
	@media screen and (max-width: 1300px) {
		padding: 2rem;
		h2 {
			font-size: 2.5rem;
			margin-top: 0rem;
		}
	}
`;

const Art = styled(motion.div)`
	.line {
		height: 0.3rem;
		background: #23d997;
		margin-bottom: 2rem;
	}
	img {
		width: 100%;
		height: 70vh;
	}
	.dyn-img {
		object-fit: cover;
	}
	.button {
		text-align: center;
		margin: 1.5rem 0;
	}
	@media screen and (max-width: 768px) {
		button {
			margin-top: -2rem;
			width: 100%;
		}
	}
`;

const Hide = styled.div`
	overflow: hidden;
`;

// Frame Animation
const Frame1 = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 9%;
	width: 100%;
	height: 100vh;
	background-color: #fffebf;
	z-index: 2;
`;

const Frame2 = styled(Frame1)`
	background-color: #ff8efb;
`;

const Frame3 = styled(Frame1)`
	background-color: #8ed2ff;
`;

const Frame4 = styled(Frame1)`
	background-color: #8effa0;
`;
export default Works;
