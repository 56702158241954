import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';


const Wave = () => {
  return (
    <WaveSvg
      viewBox="0 0 1440 363"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        initial={ { pathLength: 0, pathOffset: 1 } }
        animate={ { pathLength: 1, pathOffset: 0 } }
        transition={ { duration: 3 } }
        d="M1440,28.7c-62.1-14.8-209.8-42.9-328.3,8.3c-29.3,12.7-90.6,45.4-153.4,86.9c-23.3,15.4-40.6,28.3-68.2,48.8 c-85.1,63-118.3,93.1-142.6,110.7C631.8,367.5,472.2,349.3,435,344c-137-19.5-224.7-93.8-271.9-133.7C74.1,135,25.2,51.5,0,0"
        stroke="#23d997"
        strokeOpacity="0.3"
        strokeWidth="8"
      />
    </WaveSvg>
  )
}

const WaveSvg = styled.svg`
  position: absolute;
  left: 0;
  z-index: 1;
  @media screen and (max-width: 1300px) {
    position: absolute;
    top: 50%;
    z-index: -2;
    transform: translateY(-50%);
    path{
      stroke-width: 20px;
    }
  }
`;
export default Wave;