import React, { useState } from "react";
import styled from "styled-components";
// Animation
import { motion } from "framer-motion";
import { pageAnimation, titleAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";
import contactImg from "../img/contact.jpeg";

const ContactUs = () => {
	const [status, setStatus] = useState("");
	const submitForm = (ev) => {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				setStatus("SUCCESS");
			} else {
				setStatus("ERROR");
			}
		};
		xhr.send(data);
	};

	return (
		<StyledContact
			variants={pageAnimation}
			initial="hidden"
			animate="show"
			exit="exit"
			style={{ background: "#f2f2f2" }}
		>
			<ScrollTop />
			<div className="contact-img">
				<Title>
					<Hide>
						<motion.h2 variants={titleAnimation}>Contact Me</motion.h2>
					</Hide>
				</Title>
			</div>
			<Form>
				<h3 className="git">Get in touch</h3>
				<form
					action={`https://formspree.io/f/${process.env.REACT_APP_FORMSPREE}`}
					method="POST"
					onSubmit={submitForm}
				>
					{/* Full Name */}
					<div>
						<input
							type="text"
							name="fullName"
							className="form-control"
							placeholder="Full Name"
							required
						/>
					</div>
					{/* Email */}
					<div>
						<input
							type="email"
							name="email"
							className="form-control"
							placeholder="Email"
							required
						/>
					</div>

					{/* Subject */}
					<div>
						<input
							type="text"
							name="subject"
							className="form-control"
							placeholder="Subject"
							required
						/>
					</div>
					{/* Message */}
					<div>
						<textarea
							className="form-control"
							name="message"
							rows="5"
							placeholder="Short and sweet message"
							required
						></textarea>
					</div>
					{status === "SUCCESS" ? (
						<>
							<p style={{ textAlign: "center", color: "#23d997" }}>
								Message Received, Have a nice day!
							</p>
						</>
					) : (
						<div>
							<input type="submit" />
						</div>
					)}
					{status === "ERROR" && (
						<p style={{ textAlign: "center", color: "#ff0000" }}>
							Ooops! There was an error.
						</p>
					)}
				</form>
			</Form>
		</StyledContact>
	);
};

const StyledContact = styled(motion.div)`
	.contact-img {
		margin: 0;
		padding: 4rem;
		background: url("${contactImg}") !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		background-attachment: fixed !important;
		background-size: cover !important;
		overflow: hidden;
	}
	h2 {
		color: #fff;
		font-size: 4rem;
		font-weight: 600;
		letter-spacing: 0.4rem;
		text-shadow: -3px 2px 5px #000;
	}
	min-height: 90vh;
`;

const Title = styled.div`
	color: #000;
	text-align: center;
	@media screen and (max-width: 1300px) {
		margin-bottom: 1rem;
	}
`;

const Hide = styled.div`
	overflow: hidden;
`;

const Form = styled.div`
	width: 50%;
	margin: 4rem auto 0;
	padding-bottom: 4rem;
	.git {
		font-size: 2rem;
		text-align: center;
		margin-bottom: 2.5rem;
		color: #5a5757;
	}
	.form-control {
		font-size: 1.2rem;
	}
	@media screen and (max-width: 1300px) {
		width: 80%;
	}
	textarea {
		font-family: inherit;
	}
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		padding: 12px 20px;
		margin: 8px 0;
		display: inline-block;
		border: 1px solid #23d997;
		border-radius: 4px;
		box-sizing: border-box;
	}
	input[type="submit"] {
		width: 100%;
		background-color: #23d997;
		color: white;
		padding: 14px 20px;
		margin: 8px 0;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-size: 1.3rem;
		transition: all 0.2s ease;
		font-weight: bold;
	}
	input[type="submit"]:hover {
		background-color: #1fa574;
	}
`;
export default ContactUs;
