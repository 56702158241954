import React, { useState, useEffect } from 'react';
// Global Styling
import GlobalStyle from "./components/GlobalStyle";
import Nav from './components/Nav';
import Footer from './components/Footer';
import AboutUs from './pages/About';
import ContactUs from './pages/Contact';
import Works from './pages/Works';
import WorkDetail from './pages/WorkDetail';
import Error from './pages/Error';
import { Switch, Route, useLocation } from 'react-router-dom';
//Animation
import { AnimatePresence } from 'framer-motion';

import { client } from './components/Contentful';

const ct = process.env.REACT_APP_API_CONTENT_TYPE;
function App () {
  const [ works, setWorks ] = useState( [] );
  useEffect( () => {
    client.getEntries( {
      content_type: ct
    } )
      .then( ( response ) => setWorks( response.items ) )
      .catch( console.error );
  }, [] );

  const [ navStatus, setNavStatus ] = useState( false );
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyle />
      <Nav navStatus={ navStatus } setNavStatus={ setNavStatus } />
      <AnimatePresence exitBeforeEnter>
        <Switch location={ location } key={ location.pathname }>
          <Route exact path="/" >
            <AboutUs />
          </Route>
          <Route exact path="/work">
            <Works works={ works } />
          </Route>
          <Route exact path="/work/:slug">
            <WorkDetail works={ works } />
          </Route>
          <Route exact path="/contact">
            <ContactUs />
          </Route>
          <Route component={ Error } />
        </Switch>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
